// session.js

import graphqlClient from '../../../api/db'
import gql from 'graphql-tag'
import { extractGraphqlError } from '@/helpers'

// Initial state
const initialState = () => ({
	all: [],
	selected: {},
	isLoading: false,
	error: null,
})

const state = initialState()

// GraphQL Queries
const getAllSessionsQuery = gql`
	query AllSessions($limit: Int, $offset: Int, $isActive: Boolean) {
		allSessions(limit: $limit, offset: $offset, isActive: $isActive) {
			bikes
			duration
			endTime
			createdAt
			id
			parking {
				code
				id
			}
			paymentMethod
			sessionId
			startTime
			user {
				email
				firstName
				id
				phoneNumber
				lastName
			}
			totalAmountPaid
			sessionQRCodes {
				id
				expiresIn
				qrCode
				qrType
				usage
				used
				usedAt
				userSessionId
			}
		}
		serverTime
	}
`

// Actions
const actions = {
	async getSessions({ commit }, { limit, offset, isActive }) {
		commit('setLoading', true)

		try {
			const response = await graphqlClient.query({
				query: getAllSessionsQuery,
				variables: { limit, offset, isActive },
				fetchPolicy: 'no-cache',
			})
			const sessions = response.data.allSessions

			commit('setSessions', sessions)
			commit('setServerTime', response.data.serverTime, { root: true })
		} catch (e) {
			logger.error('Problem retrieving sessions', e)
		} finally {
			commit('setLoading', false)
		}
	},
}

// Mutations
const mutations = {
	resetState(state) {
		Object.assign(state, initialState())
	},
	setSessions(state, sessions) {
		state.all = sessions
	},
	setSessionId(state, sessionId) {
		localStorage.setItem('sessionId', sessionId)
		state.isLoggedIn = true
	},
	setLoading(state, isLoading) {
		state.isLoading = isLoading
	},
	setError(state, error) {
		state.error = error
	},
}

export default {
	namespaced: true,
	state,
	actions,
	mutations,
}
