<template>
	<div :class="{ open: detailsOpen }" class="parking-details">
		<!-- <div :style="{backgroundImage: `url(${details.image})`}" class="parking-details-hero"/> -->
		<div class="parking-details-close-button" @click="closeDetails">
			<div class="parking-details-close-button-icon" />
		</div>
		<div v-if="!details.sessionConfig" class="parking-details-content">
			<h1 class="title parking-details-content-title">{{ details.code }} - {{ details.name }}</h1>
			<div class="parking-details-info">
				<p v-if="details.address" class="parking-details-info-text address">
					{{ details.address[activeLanguage.value] || details.address.en }}
				</p>
				<p
					v-if="details.comment && details.comment[activeLanguage.value]"
					class="parking-details-info-text info"
				>
					<label v-if="details.ownerId" class="profile-input-field-label">{{
						$t('parkings.ppContactInfoLabel')
					}}</label>
					{{ details.comment[activeLanguage.value] || details.comment.en }}
				</p>
				<div class="parking-details-info-items">
					<div class="parking-details-info-item">
						<div class="parking-details-info-item-icon spots" />
						<p class="parking-details-info-item-text">Classic: {{ details.capacityClassic }}</p>
						<p class="parking-details-info-item-text">Cargo: {{ details.capacityCargo }}</p>
					</div>
					<div class="parking-details-info-item">
						<div
							:style="{ backgroundImage: `url(${details.iconUrl})` }"
							class="parking-details-info-item-icon marker"
						/>
						<p class="parking-details-info-item-text">
							{{ $t(`parkings.parkingTypes.${details.parkingTypeLabel}`) }}
						</p>
					</div>
					<div class="parking-details-info-item">
						<div class="parking-details-info-item-icon price" />
						<p v-if="bigParkingPrice" class="parking-details-info-item-text">
							&euro;{{ bigParkingPrice }}/{{ $t('parkings.yearLabel') }}
						</p>
						<p v-if="!bigParkingPrice && standardPrices" class="parking-details-info-item-text">
							Classic: &euro;{{ standardPrices }}/{{ $t('parkings.yearLabel') }}
						</p>
						<p v-if="!bigParkingPrice && cargoPrices" class="parking-details-info-item-text">
							Cargo: &euro;{{ cargoPrices }}/{{ $t('parkings.yearLabel') }}
						</p>
					</div>
					<div v-if="showCaution" class="parking-details-info-item">
						<div class="parking-details-info-item-icon key" />
						<p class="parking-details-info-item-text">
							&euro;{{ deposit }} ({{ $t('parkings.guaranteeLabel') }})
						</p>
					</div>
				</div>
			</div>
			<div class="parking-details-actions">
				<div
					v-if="!hideRequestButton"
					class="button orange parking-details-request-button"
					@click="() => startRequest()"
				>
					{{ $t('parkings.requestButtonLabel') }}
				</div>
				<a
					:href="$t('general.navigationLinks.faq')"
					class="link parking-details-actions-link"
					target="_blank"
				>
					{{ $t('parkings.faqLabel') }}
				</a>
			</div>
		</div>
		<div
			v-if="details.sessionConfig"
			class="parking-details-content session-parking-detail-content"
		>
			<h1 class="title session-parking-details-content-title">
				<img :src="details.iconUrl" class="maps-legend-info-row-image" />
				{{ details.code }} <br />
				{{ details.name }}
			</h1>
			<div class="parking-details-address-wrapper">
				<img
					v-if="details.address[activeLanguage.value].includes('(M)')"
					class="parking-details-address-wrapper-m"
					src="../../assets/images/session/Métrro.svg"
				/>
				<p class="session-parking-details-content-subtitle">
					{{
						details.address[activeLanguage.value].replace('(M)', ``) ||
						details.address.en.replace('(M)', ``)
					}}
				</p>
			</div>
			<div class="parking-detail">
				<p class="parking-detail-section-heading">{{ $t('parkings.parkingDetails.placeTitle') }}</p>
				<div class="detail-types">
					<div class="type">
						<div class="icon">
							<img src="../../assets/images/bycicle.svg" style="width: 31px" />
						</div>
						<p class="description">{{ $t('general.regular') }} : {{ details.capacityClassic }}</p>
					</div>
					<div class="type" v-if="!details.sessionConfig">
						<div class="icon">
							<img src="../../assets/images/bycicle-cargo.svg" style="width: 46px" />
						</div>
						<p class="description">Cargo: {{ details.capacityCargo }}</p>
					</div>
					<div class="type">
						<div class="icon">
							<img src="../../assets/images/euro.svg" style="width: 12px" />
						</div>
						<p class="description">
							€{{ details.sessionConfig.perHourCost }}/{{ $t('general.hour') }}
						</p>
					</div>
					<div class="type" v-if="!details.sessionConfig">
						<div class="icon">
							<img src="../../assets/images/key.svg" style="width: 21px" />
						</div>
						<p class="description">€{{ deposit }} caution</p>
					</div>
				</div>
			</div>
			<div class="parking-detail-la-session-price">
				<p class="parking-detail-section-heading">{{ $t('parkings.parkingDetails.priceTitle') }}</p>
				<div class="icon">
					<img src="../../assets/images/bycicle.svg" style="width: 32px" />
				</div>
				<p class="description">€{{ details.sessionConfig.perHourCost }}/{{ $t('general.hour') }}</p>
				<p class="text">
					{{
						$t('sessionParking.confirmation.disclaimerText') +
						' ' +
						details.sessionConfig.subscriptionFreeHours +
						' ' +
						$t('sessionParking.confirmation.disclaimerText2')
					}}
				</p>
			</div>
			<router-link
				v-if="APP_ENV_SESSION"
				class="button bg-primary-red button-shadow button-session"
				:to="{ path: '/how-it-pay', query: { parkingId: details.id } }"
			>
				{{ $t('parkings.parkingDetails.ctaText') }}
			</router-link>

			<div class="parking-detail-subscription">
				<h2 class="parking-detail-subscription-title">
					{{ $t('parkings.parkingDetails.infoTitle') }}
				</h2>
				<p class="parking-detail-subscription-description">
					<router-link
						class="color-red"
						:to="{ path: '/login', query: { from: 'parkings?parkingId:' + details.id } }"
						>{{ $t('parkings.parkingDetails.infoDetailLink') }}</router-link
					>
					{{ $t('parkings.parkingDetails.infoDetail') }}
				</p>
				<a class="color-red link-request" :href="$t('general.navigationLinks.faq')">{{
					$t('parkings.parkingDetails.infoDetailCta')
				}}</a>
				<button class="button bg-primary-red button-session" @click="() => startRequest()">
					{{ $t('parkings.parkingDetails.infoCtaText') }}
				</button>
			</div>
			<div class="slider-container">
				<!-- Swiper -->
				<div class="swiper mySwiper">
					<div class="swiper-wrapper">
						<div
							class="swiper-slide"
							v-for="(image, index) in details.images"
							:key="`parking-image-${index}`"
						>
							<img :src="image" />
						</div>
					</div>
					<div class="swiper-button-next">
						<img src="../../assets/images/icon_arrow_left_white.svg" />
					</div>
					<div class="swiper-button-prev">
						<img src="../../assets/images/icon_arrow_right_white.svg" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { DEFAULT_PRICES } from '@/config'
import Swiper from 'swiper'
import { Navigation } from 'swiper/modules'
import { APP_ENV_SESSION } from '@/config'
Swiper.use([Navigation])
import 'swiper/swiper.css'
import 'swiper/modules/navigation-element.css'
import 'swiper/modules/pagination-element.css'

/* import { logger } from '@/logger' */

export default {
	name: 'Details',
	props: {
		parkings: {
			type: Array,
			required: false,
			default: () => [],
		},
	},
	data() {
		return {
			center: { lat: 50.84797, lng: 4.350369 },
			deposit: DEFAULT_PRICES.deposit,
			swiper: null,
			APP_ENV_SESSION,
		}
	},
	watch: {
		detailsOpen() {
			setTimeout(() => {
				this.swiper = new Swiper('.swiper', {
					// Optional parameters
					// direction: 'vertical',
					loop: true,
					autoHeight: true,

					// Navigation arrows
					navigation: {
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev',
					},
				})
			}, 1000)
		},
		details() {
			this.swiper && this.swiper.update()
			this.swiper && this.swiper.slideTo(0, 600)
		},
	},
	computed: {
		...mapState({
			activeLanguage: (state) => state.language,
			details: (state) => state.parkings.details,
			detailsOpen: (state) => state.parkings.detailsOpen,
			isLoggedIn: (state) => state.authentication.isLoggedIn,
		}),
		...mapGetters('profile', {
			hasBigParkingSubscription: 'hasBigParkingSubscription',
		}),
		hideRequestButton() {
			return (
				!this.details.isCycloparking ||
				(this.details.parkingTypeName === 'BigParking' &&
					this.isLoggedIn &&
					this.hasBigParkingSubscription)
			)
		},
		showCaution() {
			return (
				this.details.parkingTypeName !== 'BigParking' &&
				!this.details.bePark &&
				!this.details.ownerId &&
				((!this.details.mobib && !this.details.airKey) || this.details.badgeAccess)
			)
		},
		standardPrices() {
			const standardPrices = []
			const otherNamePrices = []
			const spots = this.details.parkingSpots || []

			spots.forEach((s) => {
				if (s.active && !s.isCargo) {
					if (s.name === 'Standard') {
						if (!standardPrices.includes(s.amount)) {
							standardPrices.push(s.amount)
						}
					} else if (!otherNamePrices.includes(s.amount)) {
						otherNamePrices.push(s.amount)
					}
				}
			})

			let prices = false

			if (standardPrices.length) {
				prices = standardPrices.join(' or €')
			} else if (otherNamePrices.length) {
				prices = otherNamePrices.join(' or €')
			}

			return prices
		},
		cargoPrices() {
			const cargoPrices = []
			const otherNamePrices = []
			const spots = this.details.parkingSpots || []

			spots.forEach((s) => {
				if (s.active && s.isCargo) {
					if (s.name === 'Cargo') {
						if (!cargoPrices.includes(s.amount)) {
							cargoPrices.push(s.amount)
						}
					} else if (!otherNamePrices.includes(s.amount)) {
						otherNamePrices.push(s.amount)
					}
				}
			})

			let prices = false

			if (cargoPrices.length) {
				prices = cargoPrices.join(' or €')
			} else if (otherNamePrices.length) {
				prices = otherNamePrices.join(' or €')
			}

			return prices
		},
		bigParkingPrice() {
			return this.details.parkingTypeName === 'BigParking' ? this.details.bigParkingPrice : false
		},
	},
	methods: {
		// receives a place object via the autocomplete component
		...mapActions({
			closeDetails: 'parkings/closeDetails',
			toggleRequestModal: 'parkings/toggleRequestModal',
		}),

		startRequest() {
			if (this.isLoggedIn) {
				if (this.details.parkingTypeName === 'BigParking' && !this.hasBigParkingSubscription) {
					this.$router.push({ name: 'subscription' })
				} else {
					this.toggleRequestModal()
				}
			} else {
				const subscriptionModal = localStorage.getItem('subscriptionModal')
				if (!subscriptionModal) {
					localStorage.setItem('subscriptionModal', true)
				}
				this.$router.push({ name: 'login' })
			}
		},
	},
}
</script>
<style lang="scss">
@import '../../assets/scss/pages/parkings/details';
@import '../../assets/scss/pages/session/parkingDetails/index';
</style>
