<template>
	<div>
		<Loading v-if="!isLoaded || isLoading" />
		<!-- <PreviousButton /> -->
		<div class="sms-validation">
			<h1 class="title sms-validation-title">
				{{ $t('emailValidation.title') }}
			</h1>
			<form
				v-show="currentUser.hasVerificationId"
				class="sms-validation-form"
				data-vv-scope="code-form"
				@submit.prevent=""
			>
				<p class="text sms-validation-info">
					{{ $t('emailValidation.requestInfo') }}
					<strong>
						{{ currentUser.email }}
					</strong>
				</p>
				<div class="sms-validation-otp-inputs-sms">
					<input
						v-for="(input, index) in inputs"
						:key="index"
						v-model="input.value"
						@input="handleInput(index, $event)"
						@keyup="handleKeyUp(index, $event)"
						maxlength="1"
						ref="inputs"
						:class="{ 'last-input': index === inputs.length - 1, error: input.error }"
						class="sms-validation-otp-input-field input-field"
						type="text"
						inputmode="numeric"
						pattern="[0-9]*"
						:autofocus="index === 0"
					/>
					<p v-if="errors.has('code-form.accessCode')" class="input-error">
						{{ errors.first('code-form.accessCode') }}
					</p>
				</div>
				<div>
					<span class="sms-validation-link-button-link" @click="doRequestToken()">
						{{ $t('emailValidation.resendCode') }}
					</span>
				</div>

				<button
					:disabled="errors.has('code-form.accessCode') || smsCode.length < 4"
					:class="{ disabled: errors.has('code-form.accessCode') || smsCode.length < 4 }"
					class="button red sms-validation-button-confirm"
					@click="doValidateToken"
				>
					{{ $t('emailValidation.validateLabel') }}
				</button>
			</form>
		</div>
	</div>
</template>

<script>
import Loading from '@/components/Loading.vue'
import { mapGetters, mapActions, mapState } from 'vuex'
import PreviousButton from '@/components/PreviousButton.vue'

export default {
	name: 'EmailValidation',
	components: {
		Loading,
		PreviousButton,
	},
	props: {
		verification: {
			default: true,
			type: Boolean,
		},
	},
	data() {
		return {
			loading: false,
			smsCode: '',
			phoneNumber: '',
			email: '',
			localErrors: {
				email: '',
			},
			local: {
				phoneNumber: '',
			},
			passwordConfirmation: {
				value: '',
				reveal: false,
			},
			open: false,
			inputs: [{ value: '' }, { value: '' }, { value: '' }, { value: '' }],
			// localUser: Object.assign({}, this.currentUser),
		}
	},
	computed: {
		...mapState({
			currentUser: (state) => state.profile.currentUser,
			isLoading: (state) => state.authentication.isLoading,
			alert: (state) => state.alert,
			showTelForm: (state) => state.authentication.showTelForm,
		}),
		...mapGetters({
			isLoaded: 'profile/isLoaded',
		}),

		localUser() {
			return Object.assign({}, this.currentUser)
		},
	},
	created() {
		if (!this.isLoaded) {
			this.loadCurrentUser().then(() => {
				this.setLocalEmail()
			})
		} else {
			this.setLocalEmail()
		}
	},
	mounted() {
		// Auto focus first input on mobile
		this.$nextTick(() => {
			if (this.$refs.inputs && this.$refs.inputs[0]) {
				this.$refs.inputs[0].focus()
			}
		})
	},
	methods: {
		...mapActions({
			loadCurrentUser: 'profile/getCurrentUser',
			validateToken: 'authentication/validateEmailToken',
			requestToken: 'authentication/requestEmailToken',
		}),
		async doValidateToken() {
			await this.validateToken(this.smsCode)

			// this.flash(this.alert.message, this.alert.type)
		},
		async doRequestToken() {
			console.log('passwordConfirmation', this.passwordConfirmation.value)
			await this.requestToken({
				email: this.email,
				password: this.passwordConfirmation.value,
			})

			// this.flash(this.alert.message, this.alert.type)
		},
		hasLocalErrors() {
			return Boolean(this.localErrors.email) || (this.phoneNumber || '').length < 1
		},
		setLocalEmail() {
			this.email = this.currentUser.email || ''
		},
		handleInput(index, event) {
			const val = event.target.value
			if (isNaN(val)) {
				this.inputs[index].value = ''
				return
			}
			if (val !== '') {
				if (this.smsCode.length < 4) {
					// this.smsCode += String(val)
					this.smsCode = this.inputs.map((input) => input.value).join('')
					console.log('this.smsCode', this.smsCode)
				}
				const nextIndex = index + 1
				if (this.inputs[nextIndex]) {
					this.$nextTick(() => {
						this.$refs.inputs[nextIndex].focus()
					})
				}
			}
		},
		handleKeyUp(index, event) {
			const key = event.key.toLowerCase()
			if (key === 'backspace' || key === 'delete') {
				this.inputs[index].value = ''
				if (this.smsCode.length > 0) {
					this.smsCode = this.smsCode.slice(0, -1)
				}
				const prevIndex = index - 1
				if (this.inputs[prevIndex]) {
					this.$nextTick(() => {
						this.$refs.inputs[prevIndex].focus()
					})
				}
			}
		},
	},
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/pages/smsValidation';
</style>
