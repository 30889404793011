<template>
	<div class="active-session">
		<h1 class="title">{{ $t('sessionParking.sessionActive.title') }}</h1>
		<div class="active-session-id">ID {{ sessionActive[0].sessionId }}</div>
		<div class="active-session-text">{{ $t('sessionParking.sessionActive.timerTitle') }}</div>
		<div class="active-session-timer">
			{{ formattedStartTime }}
		</div>
		<div class="active-session-text">{{ $t('sessionParking.sessionActive.priceTitle') }}</div>
		<div class="active-session-amount">
			{{ '€' + totalAmountPaid }}
		</div>
		<div class="active-session-text">{{ $t('sessionParking.sessionActive.locationTitle') }}</div>
		<div class="active-session-parking-detail">
			<div class="active-session-parking-detail-heading">
				<img
					src="../../assets//images/session/icon_location_bigparking.svg"
					alt="Add Circle Icon"
					class="icon-svg"
				/>
				<div class="active-session-parking-detail-heading-right">
					<h3>{{ sessionActive[0].parking ? sessionActive[0].parking.name : '' }}</h3>
					<div class="active-session-address-wrapper">
						<img
							v-if="
								sessionActive[0].parking &&
								sessionActive[0].parking.legacyAddress.en.includes('(M)')
							"
							class="active-session-address-wrapper-m"
							src="../../assets/images/session/Métrro.svg"
						/>

						<p class="parking-address">
							{{
								sessionActive[0].parking &&
								sessionActive[0].parking.legacyAddress.en.replace('(M)', '')
							}}
						</p>
					</div>
					<!--					<p class="address">-->

					<!--						{{ sessionActive[0].parking ? sessionActive[0].parking.legacyAddress.en : '' }}-->
					<!--					</p>-->
					<p
						class="action"
						@click="$router.push('/parkings?parkingId=' + sessionActive[0].parkingId)"
					>
						{{ $t('sessionParking.sessionActive.locationBtn') }}
					</p>
				</div>
			</div>
		</div>
		<div class="active-session-cta">
			<button @click="openModal">{{ $t('sessionParking.sessionActive.confirmBtn') }}</button>
		</div>
		<div class="active-session-contact">
			<p>
				{{ $t('sessionParking.sessionActive.disclaimer') }}
			</p>
		</div>
	</div>
</template>

<script>
export default {
	name: 'activesession',
	props: {
		sessionActive: {
			type: Array,
			default: () => [],
		},
		totalAmountPaid: {
			type: String,
		},
	},
	computed: {
		formattedStartTime() {
			// Get the startTime from sessionActive
			const startTime = this.sessionActive[0].startTime
			// Convert startTime to a Date object
			const startDate = new Date(startTime)

			// Get date components
			const year = startDate.getFullYear()
			const month = (startDate.getMonth() + 1).toString().padStart(2, '0') // Months are zero-based
			const day = startDate.getDate().toString().padStart(2, '0')

			// Get time components
			const hours = startDate.getHours().toString().padStart(2, '0')
			const minutes = startDate.getMinutes().toString().padStart(2, '0')
			const seconds = startDate.getSeconds().toString().padStart(2, '0')

			// Return the formatted date and time string
			return `${hours}:${minutes}:${seconds} ${day}-${month}-${year}`
		},
	},
	data() {
		return {
			elapsedTime: '', // Variable to hold the elapsed time
			timerInterval: null, // Interval object to hold the timer
		}
	},
	methods: {
		openModal() {
			this.$emit('openModal')
		},
	},
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/pages/session/index';

.bg {
	padding-bottom: 62px;
}
.active-session-address-wrapper {
	display: flex;
	align-items: center;
	&-m {
		width: 4%;
		margin: 0 0.6rem 0.1rem 0;
	}
}
</style>
