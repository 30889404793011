<template>
	<div>
		<Loading v-if="!isLoaded || isLoading" />
		<form class="profile-form" data-vv-scope="request-form" @submit.prevent="">
			<div class="input-box profile-email-input-box">
				<label class="profile-input-field-label">
					{{ $t('signup.telephoneLabel') }}
				</label>
				<!-- :placeholder="$t('profile.phoneLabel')" -->
				<vue-tel-input
					v-model="phoneNumber"
					v-validate="'numeric'"
					:preferredCountries="['be', 'nl', 'fr']"
					:class="{ error: !!localErrors.phone }"
					:inputOptions="localizedData.options"
					name="phone"
					class="sms-validation-input-field input-phone-box profile-input-field"
					@input="onInputPhone"
				/>
				<p v-show="localErrors.phone" class="input-error">
					{{ localErrors.phone }}
				</p>
			</div>
			<div
				v-if="currentUser.status && currentUser.status !== 0"
				<div
				class="input-box profile-password-input-box"
			>
			<label class="profile-input-field-label">{{ $t('profile.confirmPassword') }}</label>
				<input
					ref="passwordConfirmation"
					v-model="passwordConfirmation.value"
					:placeholder="$t('profile.confirmPassword')"
					name="password"
					type="password"
					class="input-field profile-input-field"
				/>
				<div
					class="input-reveal-button with-label"
					tabindex="0"
					@click="doToggleReveal('passwordConfirmation')"
					@keyup.enter="doToggleReveal('passwordConfirmation')"
				>
					<div
						:class="{ revealed: passwordConfirmation.reveal }"
						class="input-reveal-button-line"
					/>
				</div>
			</div>
			<button
				:disabled="hasLocalErrors()"
				:class="{ disabled: hasLocalErrors() }"
				class="button red"
				@click="doRequestToken()"
			>
				{{ $t('smsValidation.requestTokenLabel') }}
			</button>
		</form>
	</div>
</template>

<script>
import Loading from '@/components/Loading.vue'
import { mapGetters, mapActions, mapState } from 'vuex'
import { toggleReveal } from '@/helpers'
import ValidationRequired from '@/mixins/validation-required'

export default {
	name: 'SmsValidation',
	components: {
		Loading,
	},
	mixins: [ValidationRequired],
	props: {
		verification: {
			default: true,
			type: Boolean,
		},
		from: {
			String: true,
		},
	},
	data() {
		return {
			loading: false,
			// smsCode: '',
			phoneNumber: '',
			localErrors: {
				phone: '',
			},
			local: {
				phoneNumber: '',
			},
			passwordConfirmation: {
				value: '',
				reveal: false,
			},
			telDoptions: { showDialCodeInSelection: true, showFlags: true },
			telIoptions: { showDialCode: true },
			open: false,
			options: {
				placeholder: this.$t('profile.phoneLabel'),
			},
			// localUser: Object.assign({}, this.currentUser),
		}
	},
	computed: {
		...mapState({
			currentUser: (state) => state.profile.currentUser,
			isLoading: (state) => state.authentication.isLoading,
			alert: (state) => state.alert,
			showTelForm: (state) => state.authentication.showTelForm,
		}),
		...mapGetters({
			isLoaded: 'profile/isLoaded',
		}),
		localizedData() {
			return {
				options: { placeholder: this.$t('profile.phoneLabel') },
			}
		},
		localUser() {
			return Object.assign({}, this.currentUser)
		},
	},
	created() {
		if (!this.isLoaded) {
			this.loadCurrentUser().then(() => this.setLocalPhone())
		} else {
			this.setLocalPhone()
		}
	},
	methods: {
		...mapActions({
			loadCurrentUser: 'profile/getCurrentUser',
			// validateToken: 'authentication/validateToken',
			requestToken: 'authentication/requestToken',
		}),
		// async doValidateToken() {
		// 	await this.validateToken(this.smsCode)

		// 	// this.flash(this.alert.message, this.alert.type)
		// },
		async doRequestToken() {
			// We store the phoneNumber in a local val on the 'onInputPhone' event because of a bug with the vue-phone-number-input component
			// When we copy-past a phone number in the field when it was already filled-in the country-code was not added.
			// This way, we make sure that the phone number is always correct.
			this.phoneNumber = this.local.phoneNumber

			await this.requestToken({
				phone: this.phoneNumber,
				password: this.passwordConfirmation.value,
			})

			// this.flash(this.alert.message, this.alert.type)
		},
		hasLocalErrors() {
			return Boolean(this.localErrors.phone) || (this.phoneNumber || '').length < 1
		},
		onInputPhone(value, phoneObject) {
			this.local.phoneNumber = phoneObject.number

			const empty = (value || '').length < 1

			this.localErrors.phone = empty || phoneObject.valid ? '' : this.$t('error.notValidPhone')
		},

		setLocalPhone() {
			this.phoneNumber = this.currentUser.phoneNumber || this.currentUser.phoneNumberTemp || ''
		},
		doShowTelForm() {
			this.$store.commit('authentication/setShowTelForm', true)
		},
		doToggleReveal(field) {
			this[field].reveal = toggleReveal(this[field], this.$refs[field])
		},
	},
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/pages/smsValidation';
@import '../../assets/scss/pages/profile/index';
</style>
