<template>
	<div
		id="app"
		:class="{ 'is-parking': $router.history.current.path === '/parkings' }"
		@click="handleAppClick"
	>
		<nav
			:class="{
				'nav bg-blue':
					$router.history.current.path === '/main-door-qr' ||
					$router.history.current.path === '/session-start-qr' ||
					$router.history.current.path === '/qr-2' ||
					$router.history.current.path === '/main-door-exit-qr' ||
					$router.history.current.path === '/entering-qr' ||
					$router.history.current.path === '/session-end-qr',
				nav:
					$router.history.current.path !== '/main-door-qr' &&
					$router.history.current.path !== '/session-start-qr' &&
					$router.history.current.path !== '/qr-2' &&
					$router.history.current.path !== '/main-door-exit-qr' &&
					$router.history.current.path !== '/entering-qr' &&
					$router.history.current.path !== '/session-end-qr',
			}"
		>
			<div :class="{ open: menuOpen }" class="nav-menu-button mobile" @click="toggleMenu">
				<div class="nav-menu-button-piece top" />
				<div class="nav-menu-button-piece middle" />
				<div class="nav-menu-button-piece bottom" />
			</div>
			<a :href="'/'" class="nav-image-link">
				<img
					class="nav-image"
					:src="
						require($router.history.current.path === '/main-door-qr' ||
							$router.history.current.path === '/session-start-qr' ||
							$router.history.current.path === '/qr-2' ||
							$router.history.current.path === '/main-door-exit-qr' ||
							$router.history.current.path === '/entering-qr' ||
							$router.history.current.path === '/session-end-qr'
							? './assets/images/logo_secondary_negative.svg'
							: './assets/images/logo-pb-carre.png')
					"
					alt=""
				/>
			</a>
			<div :class="{ open: menuOpen }" class="nav-link-pages">
				<router-link :to="{ name: 'profile' }" class="nav-link" @mousedown.native="clickLink">
					{{ $t('general.profileLink') }}
				</router-link>
				<router-link class="nav-link" to="/parkings" @mousedown.native="clickLink">
					{{ $t('general.navigation.parkings') }}
				</router-link>
				<router-link class="nav-link nav-session" to="/how-it-works" @mousedown.native="clickLink">
					{{ $t('general.navigation.sessionParking') }}
				</router-link>
				<router-link
					v-if="isLoggedIn && !hasBigParkingSubscription"
					class="nav-link"
					to="/subscription"
					@mousedown.native="clickLink"
				>
					{{ $t('general.subscriptionLink') }}
				</router-link>
				<!-- <a :href="$t('general.navigationLinks.parkings')" target="_blank" class="nav-link" @mousedown.native="clickLink">
					{{ $t("general.navigation.parkings") }}
				</a> -->
				<a
					:href="$t('general.navigationLinks.shareGarage')"
					target="_blank"
					class="nav-link"
					@mousedown.native="clickLink"
				>
					{{ $t('general.navigation.shareGarage') }}
				</a>
				<a :href="$t('general.navigationLinks.faq')" class="nav-link" @mousedown.native="clickLink">
					{{ $t('general.navigation.faq') }}
				</a>
				<a
					:href="$t('general.navigationLinks.contact')"
					class="nav-link"
					@mousedown.native="clickLink"
				>
					{{ $t('general.navigation.contact') }}
				</a>
				<button v-if="isLoggedIn" class="nav-action button red mobile" @mousedown="doLogout">
					{{ $t('general.logoutLabel') }}
				</button>
			</div>
			<div class="nav-actions">
				<DropdownLanguage
					:selected="activeLanguage"
					:options="UILanguages"
					class="nav-action language-dropdown"
					@on-click="selectLanguage"
				/>
				<button v-if="isLoggedIn" class="nav-action button red desktop" @click="doLogout">
					{{ $t('general.logoutLabel') }}
				</button>
			</div>
		</nav>
		<router-view
			:class="{
				'no-footer-mobile': $router.history.current.path === '/parkings',
				'is-parking': $router.history.current.path === '/parkings',
			}"
			class="bg content"
		/>
		<flash-message class="flash-message" outerClass="flashpool flash__wrapper" />
		<div class="footer-top" v-if="$router.history.current.path === '/how-it-works'">
			<div class="footer-top-desktop">
				<img src="./assets/images/Cyclo-logo-bike.png" />
				<div class="footer-top-desktop-address">
					<span
						>{{ $t('general.navigation.title') }}
						<a :href="$t('general.navigation.titleLink')">{{
							$t('general.navigation.linkTitle')
						}}</a></span
					>
					<p>
						{{ $t('general.navigation.cycloName') }}
						<br />
						{{
							$t('general.navigation.street') +
							' ' +
							$t('general.navigation.zip') +
							' ' +
							$t('general.navigation.city')
						}}

						<br />
						{{ $t('general.navigation.vat') }}
					</p>
				</div>
				<div class="footer-top-desktop-social">
					<a href="https://www.facebook.com/cycloparking" target="_blank"
						><img src="./assets/images/session/icon_fb_black.png"
					/></a>
				</div>
			</div>
			<div class="footer-top-mobile">
				<div class="footer-top-mobile-first">
					<img src="./assets/images/Cyclo-logo-bike.png" />
					<span
						>{{ $t('general.navigation.title') }}
						<a :href="$t('general.navigation.titleLink')">{{
							$t('general.navigation.linkTitle')
						}}</a></span
					>
				</div>
				<div class="footer-top-mobile-end">
					<p>
						{{ $t('general.navigation.cycloName') }}
						<br />
						{{
							$t('general.navigation.street') +
							' ' +
							$t('general.navigation.zip') +
							' ' +
							$t('general.navigation.city')
						}}
						<br />
						{{ $t('general.navigation.vat') }}
					</p>
					<div class="footer-top-mobile-end-social">
						<a href="https://www.facebook.com/cycloparking" target="_blank"
							><img src="./assets/images/session/icon-fb-blue.png"
						/></a>
					</div>
				</div>
			</div>
		</div>
		<footer
			v-if="
				$router.history.current.path !== '/main-door-qr' &&
				$router.history.current.path !== '/session-start-qr' &&
				$router.history.current.path !== '/qr-2' &&
				$router.history.current.path !== '/main-door-exit-qr' &&
				$router.history.current.path !== '/entering-qr' &&
				$router.history.current.path !== '/session-end-qr'
			"
			:class="{ 'no-footer-mobile': $router.history.current.path === '/parkings' }"
			class="footer"
		>
			<a
				:href="$t('general.navigationLinks.disclaimer')"
				class="nav-link footer-link"
				@mousedown.native="clickLink"
			>
				{{ $t('general.navigation.disclaimer') }}
			</a>
			<a
				:href="$t('general.navigationLinks.privacy')"
				class="nav-link footer-link"
				@mousedown.native="clickLink"
			>
				{{ $t('general.navigation.privacy') }}
			</a>
			<router-link
				:to="{ name: 'myparkings' }"
				class="nav-link footer-link"
				@mousedown.native="clickLink"
			>
				{{ $t('general.myParkingsLink') }}
			</router-link>
			<router-link
				v-if="currentUser.admin"
				:to="{ name: 'admin' }"
				class="nav-link footer-link"
				@mousedown.native="clickLink"
			>
				{{ $t('general.adminLink') }}
			</router-link>
			<!-- <div class="footer-info-icon">
				<img src="./assets/images/icon_Info_white.svg" />
			</div> -->
			<!-- <a
				:href="$t('general.navigationLinks.about')"
				class="nav-link footer-link"
				@mousedown.native="clickLink"
			>
				{{ $t('general.navigation.about') }}
			</a>
			
			 --></footer>
	</div>
</template>

<script>
import DropdownLanguage from '@/components/form/DropdownLanguage.vue'
import { mapActions, mapState, mapGetters } from 'vuex'
import { UILanguages } from '@/config'
import i18n from '@/i18nVeeValidate'

// import { logger } from '@/logger'

require('vue-flash-message/dist/vue-flash-message.min.css')
require('vue-tel-input/dist/vue-tel-input.css')

export default {
	name: 'App',
	components: {
		DropdownLanguage,
	},
	data: () => ({
		UILanguages,
		menuOpen: false,
	}),
	computed: {
		...mapState({
			activeLanguage: (state) => state.language,
			isLoggedIn: (state) => state.authentication.isLoggedIn,
			currentUser: (state) => state.profile.currentUser,
			alert: (state) => state.alert,
			parkingLegendOpen: (state) => state.parkings.legendOpen,
		}),
		...mapGetters('profile', {
			hasBigParkingSubscription: 'hasBigParkingSubscription',
		}),

		// ...mapGetters({
		// 	IsLoggedIn: 'authentication/isLoggedIn',
		// }),
	},
	watch: {
		'alert.message'(newVal) {
			if (newVal) {
				this.flash(this.alert.message, this.alert.type)
				this.clearAlert()
			}
		},
		'activeLanguage.value'(newVal) {
			i18n.locale = newVal
			i18n.watchLocale() // Trigger title update when language changes
		},
	},
	created() {
		// logger.debug('active language:', this.activeLanguage.value, this.$router.history.current.path)
		i18n.locale = this.activeLanguage.value
	},
	methods: {
		...mapActions({
			selectLanguage: 'selectLanguage',
			toggleParkingsLegend: 'parkings/toggleLegend',
		}),
		...mapActions('authentication', {
			logout: 'logout',
		}),
		...mapActions('alert', {
			clearAlert: 'clear',
		}),
		toggleMenu() {
			this.menuOpen = !this.menuOpen
		},
		doLogout(e) {
			this.clickLink(e)
			this.logout()
			this.$router.push('/login')
		},
		clickLink(e) {
			e.preventDefault()

			if (this.menuOpen) {
				window.setTimeout(() => this.toggleMenu(), 200)
			}
		},
		handleAppClick(e) {
			// to close map legend by clicking anywhere
			if (
				e.target.closest('.is-parking') &&
				!e.target.closest('.maps-legend') &&
				this.parkingLegendOpen
			) {
				this.toggleParkingsLegend()
			}
		},

		// IsLoggedIn() {
		// 	Return !isEmpty(localStorage.getItem('token'))
		// },
	},
}
</script>

<style lang="scss">
.bg {
	background-color: #fffcf2;
	padding-bottom: 202px;
}
@import './assets/scss/general';
</style>
